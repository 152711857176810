export default {
  userID(state) {
    return state.userID;
  },
  firstName(state) {
    return state.firstName;
  },
  lastName(state) {
    return state.lastName;
  },
  email(state) {
    return state.email;
  },
  image(state) {
    return state.image;
  },
  role(state) {
    return state.role;
  },
  phone(state) {
    return state.phone;
  },
  loadChat(state) {
    return state.loadChat;
  },
  phoneCode(state) {
    return state.phoneCode;
  },
  token(state) {
    return state.token;
  },
  isAuthenticated(state) {
    if (state.token) {
      return true;
    } else {
      return false;
    }
  },
  isAdmin(state) {
    if (
      state.role === "admin" ||
      state.role === "super" ||
      state.role === "ceo"
    ) {
      return true;
    } else {
      return false;
    }
  },
  didAutoLogout(state) {
    return state.autoLogout;
  },
};
