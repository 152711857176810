export default function () {
  return {
    userID: null,
    role: null,
    phone: null,
    phoneCode: null,
    lastName: null,
    firstName: null,
    email: null,
    image: null,
    token: null,
    autoLogout: false,
    loadChat: false,
  };
}
